//Action Type
import {contactAPI, courseAPI} from "../api/api";

const SET_COURSE = 'mate/courseReducer/SET_COURSE'
const SET_LIST_COURSES = 'mate/courseReducer/SET_LIST_COURSES'


let initialState = {
    course: '',
    listCourses: ''
}

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COURSE:
            return {
                ...state,
                course: action.data
            }
        case SET_LIST_COURSES:
            return {
                ...state,
                listCourses: action.data
            }
        default:
            return state
    }
}

//Action Creators
let setCourse = (data) => ({type: SET_COURSE, data})
let setListCourses = (data) => ({type: SET_LIST_COURSES, data})

//Thunk
export let getCourse = (slug) => async (dispatch) => {
    let data = await courseAPI.getCourseDetail(slug)
    dispatch(setCourse(data))
    return data
}

export let getListCourses = () => async (dispatch) => {
    let data = await courseAPI.getCourses()
    dispatch(setListCourses(data))
    return data
}

export let sendEmail = (body) => async () => {
    await contactAPI.sendEmail(body)
}

export default courseReducer
