import './Contact.scss'
import {Controller, useForm} from "react-hook-form";
import payMe from '../../assets/image/payme.png'
import click from '../../assets/image/click.png'
import {message, Select} from 'antd';
import {useMediaQuery} from "react-responsive/src";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getListCourses, sendEmail} from "../../redux/courseReducer";

let {Option} = Select
const Contact = () => {
    const dispatch = useDispatch()
    const {register, control, handleSubmit, reset} = useForm();
    let [openOneClick, setOpenOneClick] = useState(false)
    let [openPublic, setOpenPublic] = useState(true)
    const isMobile = useMediaQuery({query: '(max-width: 576px)'})
    const listCourses = useSelector(state => state.coursePage.listCourses)
    const onSubmit = (data) => {
        dispatch(sendEmail(data)).then(() => {
            reset()
            message.success('Ваша заявка отправлена');
        })
    }
    if (!listCourses) {
        dispatch(getListCourses())
        return <div>Loading</div>
    }
    return (
        <div className="container">
            <div className="contact" id="contact">
                {openPublic &&
                <div className="publicLesson">
                    <div className="title">
                        <h3>Запишись на урок</h3>
                        <p className="subTitle">Записывайся на открытые уроки, которые проходят каждую неделю чтобы
                            почувствовать атмосферу
                            МАТЕ!</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Имя</p>
                        <input {...register("first_name")} required type='text' placeholder="Александр"/>
                        <p>Фамилия</p>
                        <input {...register("last_name")} required type='text' placeholder="Пушкин"/>
                        <p>Возраст</p>
                        <input {...register("age")} required type='number' min={10} placeholder="Возраст"/>
                        <p>Какая программа тебя интересует?</p>
                        <Controller
                            name='course'
                            control={control}
                            defaultValue="Выбери программу:"
                            render={({field}) => <Select {...field}
                            >
                                {listCourses.map((c, key) => <Option key={key} value={c.id}>{c.title}</Option>)}
                            </Select>}
                        />
                        <div className="button">
                            <button type="submit">Записаться</button>
                            {isMobile && <button className="buyOneClick" onClick={() => {
                                setOpenOneClick(true)
                                setOpenPublic(false)
                            }}>Купить курс в один клик</button>}
                        </div>
                    </form>
                </div>}
                {!isMobile && <div className="oneClick">
                    <div className="title">
                        <h3>Купи курс в один клик</h3>
                        <p className="subTitle">Курс можно приобрести с помощью Click и Payme</p>
                    </div>
                    <form>
                        <p>Имя</p>
                        <input placeholder="Александр"/>
                        <p>Фамилия</p>
                        <input placeholder="Пушкин"/>
                        <p>Номер телефона</p>
                        <input placeholder="+998901234567"/>
                        <p>Адрес электронной почты</p>
                        <input placeholder="example@gmail.com"/>
                        <div className="buttons">
                            <button type="submit">Оплата с помощью <img src={payMe} alt="PayMe"/></button>
                            <button type="submit">Оплата с помощью <img src={click} alt="Click"/></button>
                        </div>
                    </form>
                </div>}
                {isMobile && openOneClick && <div className="oneClick">
                    <div className="title">
                        <h3>Купи курс в один клик</h3>
                        <p className="subTitle">Курс можно приобрести с помощью Click и Payme</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Имя</p>
                        <input {...register("firstName")} placeholder="Александр"/>
                        <p>Фамилия</p>
                        <input {...register("secondName")} placeholder="Пушкин"/>
                        <p>Номер телефона</p>
                        <input {...register("phone")} placeholder="+998901234567"/>
                        <p>Адрес электронной почты</p>
                        <input {...register("phone")} placeholder="example@gmail.com"/>
                        <div className="buttons">
                            <button type="submit">Оплата с помощью <img src={payMe} alt="PayMe"/></button>
                            <button type="submit">Оплата с помощью <img src={click} alt="Click"/></button>
                        </div>
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default Contact;
