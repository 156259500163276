import './Reviews.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import student1 from '../../assets/image/student1.png'
import fire from '../../assets/image/fire.svg'
import SwiperCore, {Autoplay} from 'swiper';

SwiperCore.use([Autoplay]);

const Reviews = () => {
    return (
        <div className="container">
            <div className="reviews">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay
                >
                    <SwiperSlide>
                        <div className="slideContainer">
                            <div className="avatar">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <div className="student">
                                    <img src={student1} alt="Student"/>
                                    <p className="name">Асрор Абдувасиков, <br/> Вackend разработка </p>
                                </div>
                            </div>
                            <div className="text">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <p>Как ведутся, поддержка менторов, других студентов, открытый с утра и до вечера
                                    co-working, друзья, - то, что мне нравится о MATE больше всего.
                                    Что трудно: приходится много искать дополнительной информации с разных ресурсов,
                                    чтобы сделать практические задания. Я не думаю, что это плохо. Мы учимся сами
                                    находить информацию, использовать её. А любой программист скажет, что умение гуглить
                                    – это из одно главных навыков любого разработчика.</p>
                                <p className="name">Асрор Абдувасиков, Вackend разработка </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slideContainer">
                            <div className="avatar">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <div className="student">
                                    <img src={student1} alt="Student"/>
                                    <p className="name">Асрор Абдувасиков, <br/> Вackend разработка </p>
                                </div>
                            </div>
                            <div className="text">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <p>Как ведутся, поддержка менторов, других студентов, открытый с утра и до вечера
                                    co-working, друзья, - то, что мне нравится о MATE больше всего.
                                    Что трудно: приходится много искать дополнительной информации с разных ресурсов,
                                    чтобы сделать практические задания. Я не думаю, что это плохо. Мы учимся сами
                                    находить информацию, использовать её. А любой программист скажет, что умение гуглить
                                    – это из одно главных навыков любого разработчика.</p>
                                <p className="name">Асрор Абдувасиков, Вackend разработка </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slideContainer">
                            <div className="avatar">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <div className="student">
                                    <img src={student1} alt="Student"/>
                                    <p className="name">Асрор Абдувасиков, <br/> Вackend разработка </p>
                                </div>
                            </div>
                            <div className="text">
                                <span><img src={fire} alt="Fire"/><h2 className="title">Отзывы студентов</h2></span>
                                <p>Как ведутся, поддержка менторов, других студентов, открытый с утра и до вечера
                                    co-working, друзья, - то, что мне нравится о MATE больше всего.
                                    Что трудно: приходится много искать дополнительной информации с разных ресурсов,
                                    чтобы сделать практические задания. Я не думаю, что это плохо. Мы учимся сами
                                    находить информацию, использовать её. А любой программист скажет, что умение гуглить
                                    – это из одно главных навыков любого разработчика.</p>
                                <p className="name">Асрор Абдувасиков, Вackend разработка </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Reviews;
